import { put } from 'redux-saga/effects';

import { getToken, getRefreshToken } from '../utils/token';
import { postLogin } from '../actions/asyncActions';

export default function* () {
    const token = getToken();
    const refresh_token = getRefreshToken();

    if (token) {
        yield put(postLogin.success({
            token,
            refresh_token
        }));
    }
}
